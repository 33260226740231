// NOTE : use HorizontalScroller instead of this component as we will be removing this component in future
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import classes from './HorizontalScrollIndicators.module.scss';

const HorizontalScrollIndicators = ({
  children,
  triggerSize = 40,
  isArrowsEnabled = true,
  className,
  selectedChildElement,
  styles = { leftIndicator: '', rightIndicator: '' },
  padding = 0,
  ...rest
}) => {
  const containerRef = useRef(null);
  const [indicatorVisible, setIndicatorVisible] = useState({ left: false, right: false });

  const changeHandler = () => {
    const indicators = { right: true, left: true };
    if (
      Math.ceil(containerRef.current.scrollLeft + containerRef.current.clientWidth) >=
      containerRef.current.scrollWidth - triggerSize
    ) {
      indicators.right = false;
    }
    if (containerRef.current.scrollLeft <= triggerSize) {
      indicators.left = false;
    }
    setIndicatorVisible(indicators);
  };
  const throttledChangeHandler = useMemo(() => throttle(changeHandler, 400), []);
  useEffect(() => {
    if (typeof window === 'undefined') {
      return () => {};
    }
    const eventListener = containerRef.current;
    eventListener.addEventListener('scroll', throttledChangeHandler);
    window.addEventListener('resize', throttledChangeHandler);
    return () => {
      eventListener.removeEventListener('scroll', throttledChangeHandler);
      window.removeEventListener('resize', throttledChangeHandler);
    };
  }, [throttledChangeHandler]);

  const manualScroll = useCallback(
    direction => () => {
      containerRef.current.scrollTo({
        left:
          containerRef.current.scrollLeft + ((direction === 'right' ? 1 : -1) * containerRef.current.clientWidth) / 2,
        behavior: 'smooth',
      });
    },
    [],
  );

  useEffect(() => {
    // Scroll to the selected child element so that it can be seen
    if (selectedChildElement) {
      const containerBoundingRectLeft = containerRef.current.getBoundingClientRect()?.left;
      const buffer = containerBoundingRectLeft + (isArrowsEnabled ? 15 + padding : 0); // 15 is the width of the scroll indicator
      if (containerRef?.current?.scrollLeft + containerBoundingRectLeft + padding > selectedChildElement.offsetLeft) {
        containerRef.current.scrollTo({
          left: selectedChildElement.offsetLeft - buffer,
          behavior: 'smooth',
        });
      }
      if (
        containerRef?.current?.scrollLeft + containerRef?.current?.clientWidth - containerBoundingRectLeft <
        selectedChildElement.offsetLeft + selectedChildElement.clientWidth + padding
      ) {
        containerRef.current.scrollTo({
          left:
            selectedChildElement.offsetLeft +
            selectedChildElement.clientWidth -
            containerRef.current.clientWidth +
            buffer,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedChildElement, isArrowsEnabled]);

  useEffect(() => {
    changeHandler();
    return () => {
      throttledChangeHandler.cancel();
    };
  }, [throttledChangeHandler]);

  return (
    <div className={classnames(classes.arrowAnchor, className)} {...rest}>
      <div ref={containerRef} className={classes.scrollContainer}>
        {isArrowsEnabled && (
          <button
            className={classnames(classes.scrollIndicator, classes.scrollIndicatorLeft, {
              [classes.indicatorVisible]: indicatorVisible.left,
              [styles?.leftIndicator]: !!styles?.leftIndicator,
            })}
            type="button"
            onClick={manualScroll('left')}
            key={`left_${indicatorVisible.right ? 'visible' : 'hidden'}`}
          >
            <SpriteIcon icon="arrow_back_ios" size={15} />
          </button>
        )}
        {children}
        {isArrowsEnabled && (
          <button
            className={classnames(classes.scrollIndicator, classes.scrollIndicatorRight, {
              [classes.indicatorVisible]: indicatorVisible.right,
              [styles?.rightIndicator]: !!styles?.rightIndicator,
            })}
            type="button"
            onClick={manualScroll('right')}
            key={`right_${indicatorVisible.right ? 'visible' : 'hidden'}`}
          >
            <SpriteIcon icon="arrow_forward_ios" size={15} />
          </button>
        )}
      </div>
    </div>
  );
};

export default HorizontalScrollIndicators;
