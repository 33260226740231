/* eslint-disable consistent-return */
import React, { useEffect, useCallback, useState } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useTranslation } from 'src/i18n';
import CommonTrans from 'components/Globals/CommonTrans';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import useNewTracking from 'components/Globals/Analytics';
import LinkButton from 'components/uiLibrary/LinkButton';
import {
  COMPONENTS,
  GOOGLE_OLD_TRACKING_SERVICES,
  PAYWALL_SECTION_MAP,
  SECTIONS,
  SUB_COMPONENTS,
  ACTION_CALLBACK_STATUS,
} from 'components/Globals/Analytics/constants';
import Modal from 'components/uiLibrary/Modal';
import Image from 'components/uiLibrary/Image';
import { useActiveProfileData } from 'utils/hooks/useAuthenticatedUser';
import { PAYWALL_TYPES, TP } from 'constants/index';
import { TRACK_EVENTS, PAYWALL_EVENT_IDS } from 'utils/tracking';
import useAppContext from 'utils/hooks/useAppContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { usePaywallContext } from 'utils/hooks/usePaywallContext';
import Typography from 'components/uiLibrary/Typography';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import Testimonials from 'components/Globals/Testimonials';
import LogoIcon from 'public/images/ob-icon.svg';
import PaywallNudges from '../PaywallNudges';
import PaywallContent from '../PaywallContent';
import classes from './PaywallModal.module.scss';

const CustomerLogos = dynamic(() => import('components/Globals/CustomerLogos'));

const PaywallModal = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { showPaywall } = usePaywallContext();
  const { setIsLoginDialog, isLoginDialog } = useDialogs();
  const { isMobile } = useDeviceTypeLayouts();
  const [slideDown, setSlideDown] = useState(true);
  const { isLoggedIn, paywallType, setPaywallType } = useAppContext();
  const isHardWall = paywallType === PAYWALL_TYPES.HARD_WALL;
  const activeProfile = useActiveProfileData();
  const track = useNewTracking();
  const hasActiveProfile = !!activeProfile?.id;

  useEffect(() => {
    const resetBodyStyles = () => {
      if (document && document.body) {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
      }
    };

    if (typeof window !== 'undefined' && document && document.body) {
      if (!slideDown || isHardWall) {
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${scrollbarWidth}px`;
      } else {
        setTimeout(resetBodyStyles, 100);
      }
    }

    return resetBodyStyles;
  }, [isHardWall, slideDown, isLoginDialog]);

  useEffect(() => {
    if (isHardWall) {
      setSlideDown(false);
    }
  }, [isHardWall]);

  useEffect(() => {
    if (showPaywall) {
      track.click(
        {
          name: TRACK_EVENTS.VIEW_PAYWALL,
          data: {
            actionType: paywallType,
          },
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );

      track.actionCallback({
        section: paywallType ? PAYWALL_SECTION_MAP[paywallType] : SECTIONS.PAYWALL,
        component: COMPONENTS.MODAL,
        meta: {
          step: paywallType,
          actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
          isOpen: true,
        },
      });
      setSlideDown(false);
    }
  }, [showPaywall, track, paywallType]);

  const togglePaywall = () => {
    const paywallContent = document.getElementById('paywallContent');
    paywallContent.classList.toggle('paywall__main__show');
  };

  const ignorePaywallHandler = useCallback(async () => {
    if (isHardWall) {
      return null;
    }
    if (!isLoggedIn) {
      togglePaywall();
      setSlideDown(prevValue => !prevValue);
    }
    if (!slideDown) {
      if (paywallType) {
        track.closePaywall(paywallType);
        track.click(
          {
            name: TRACK_EVENTS.CLICK,
            data: {
              id: PAYWALL_EVENT_IDS.PAYWALL_IGNORE,
              actionType: paywallType,
            },
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      }

      if (!paywallType) {
        track.click({
          section: SECTIONS.PAYWALL,
          component: COMPONENTS.EXPANDABLE_CARD,
          subComponent: SUB_COMPONENTS.SHOW_HIDE_TOGGLE,
          meta: {
            actionType: paywallType,
            isOpen: slideDown,
          },
        });
      }
    }

    if (slideDown) {
      track.click({
        section: SECTIONS.PAYWALL,
        component: COMPONENTS.EXPANDABLE_CARD,
        subComponent: SUB_COMPONENTS.SHOW_HIDE_TOGGLE,
        meta: {
          actionType: paywallType,
          isOpen: slideDown,
        },
      });
    }
    setPaywallType('');
  }, [isHardWall, isLoggedIn, slideDown, track, paywallType, setPaywallType]);

  return (
    <Modal
      styles={{
        drawerRoot: classnames(classes.drawerRoot, {
          [classes.removeDrawerRootcss]: slideDown,
        }),
        modalContainer: classes.modalContainer,
        backdrop: classnames({
          [classes.disabled]: isHardWall,
          [classes.backdrop]: slideDown && !isHardWall,
        }),
        content: classes.drawerContent,
        drawerPaper: classnames(classes.drawerPaper, {
          [classes.withSlideStyle]: slideDown,
        }),
      }}
      className={classes.paywall__modal}
      isOpen={!isLoggedIn || showPaywall}
      onClose={ignorePaywallHandler}
      isDrawer
      disableEnforceFocus
      blockBackgroundScroll={isMobile}
    >
      <div
        className={classnames(classes.paywallModalHeader, {
          [classes.paywallModalHeader__hideContent]: slideDown,
        })}
        onClick={e => {
          e.stopPropagation();
          ignorePaywallHandler();
        }}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <div className={classes.paywallModalHeader__wrapper}>
          <div className={classes.paywallModalHeader__leftSection}>
            <CommonTrans
              i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
              ns="NS_APP_GLOBALS"
              components={{
                ul: <ul className={classes.listWorks} />,
                li: <li />,
              }}
            />
          </div>
          <div className={classes.paywallModalHeader__rightSection}>
            {!isLoggedIn && (
              <div className={classes.paywallModalHeader__rightSection_title}>
                <Image
                  src={LogoIcon}
                  alt="Operabase Home"
                  lazy={false}
                  className={classes.logoIcon}
                  disableCloudinary
                  disableNextImage
                />
                <Typography size={isMobile ? 12 : 16} weight="bold">
                  {t('FN_RIGHT_PAYWAL_HEADER_TITLE')}
                </Typography>
              </div>
            )}
            <div className={classes.paywallModalHeader__rightSection_actions}>
              {!isLoggedIn && (
                <LinkButton
                  styles={{ root: classes.paywallModalHeader__rightSection_actions_titleLogin }}
                  variant="text"
                  onClick={e => {
                    e.stopPropagation();
                    track.click(
                      {
                        name: TRACK_EVENTS.CLICK,
                        data: {
                          id: PAYWALL_EVENT_IDS.PAYWALL_LOGIN_REGISTER_POPUP,
                          actionType: isHardWall ? PAYWALL_TYPES.HARD_WALL : PAYWALL_TYPES.SOFT_WALL,
                        },
                      },
                      GOOGLE_OLD_TRACKING_SERVICES,
                    );
                    track.click({
                      section: SECTIONS.PAYWALL,
                      component: COMPONENTS.EXPANDABLE_CARD,
                      subComponent: SUB_COMPONENTS.LOGIN_CTA,
                      meta: {
                        actionType: paywallType,
                        isOpen: !slideDown,
                      },
                    });

                    setIsLoginDialog({
                      isOpen: true,
                      source: isHardWall ? PAYWALL_TYPES.HARD_WALL : PAYWALL_TYPES.SOFT_WALL,
                    });
                  }}
                >
                  {t(`${TP}.LOGIN_REGISTER`)}
                  <SpriteIcon
                    icon="chevron_right"
                    size={16}
                    className={classnames(
                      classes.paywallModalHeader__rightSection_actions_titleLogin_arrowRightIconNone,
                    )}
                  />
                </LinkButton>
              )}
              {!isHardWall && (
                <div
                  className={classnames(classes.buttonArrowDown, {
                    [classes.btnCta__close]: slideDown,
                  })}
                  onClick={e => {
                    e.stopPropagation();
                    ignorePaywallHandler();
                  }}
                  role="button"
                  tabIndex={0}
                  aria-label="Close Paywall Modal"
                  onKeyDown={() => {}}
                >
                  <SpriteIcon
                    className={classnames(classes.btnCta)}
                    icon={isLoggedIn ? 'close' : 'chevron_right'}
                    size={16}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        id="paywallContent"
        className={classnames(classes.paywall__main, { [classes.paywall__main__show]: !slideDown })}
      >
        <div
          className={classnames(classes.paywall__modal__wrapper, {
            [classes.paywall__modal__wrapper__hardWall]: isLoggedIn && hasActiveProfile,
            [classes.paywall__modal__ignore]: slideDown,
          })}
          id="paywall_active"
        >
          <>
            <div className={classes.paywall__modal__content}>
              <PaywallContent isHardWall={isHardWall} isLoggedIn={isLoggedIn && hasActiveProfile} />
            </div>
            <div className={classes.paywall__modal__nudges}>
              <PaywallNudges
                isHardWall={isHardWall}
                hasActiveProfile={hasActiveProfile}
                isLoggedIn={isLoggedIn}
                setPaywallType={setPaywallType}
                paywallType={paywallType}
              />
            </div>
          </>
        </div>
        <div className={classes.footer}>
          {isMobile && (
            <Typography variant="p" className={classes.title} size={12} italic>
              {t(`${TP}.FN_LOGIN_POPUP_BOTTOM_SECTION_TITLE`)}
            </Typography>
          )}
          <div className={classes.footer__customerLogo}>
            <CustomerLogos
              showTitle={!isMobile}
              rowHeight={isMobile ? 35 : 40}
              count={isMobile ? 4 : 16}
              onClickViewAll={() => !isHardWall && setSlideDown(true)}
              className={classes.footer__fluid}
              allowShowAll
            />
          </div>
          <div className={classes.testimonials}>
            <Typography variant="div" size={12} weight="bold" className={classes.testimonialTitle}>
              {t(`${TP}.FN_CONVERSION_TESTIMONIALS`)}
            </Typography>

            <HorizontalScrollIndicators
              styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
              triggerSize={0}
              isArrowsEnabled
            >
              <Testimonials hideTitle inLine limit={isMobile ? 6 : 12} className={classes.testimonials__list} />
            </HorizontalScrollIndicators>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaywallModal;
