/* eslint-disable import/no-cycle */
import React, { useCallback, useMemo } from 'react';

import useNewTracking from 'components/Globals/Analytics';
import {
  GOOGLE_OLD_TRACKING_SERVICES,
  SECTIONS,
  SUB_COMPONENTS,
  COMPONENTS,
} from 'components/Globals/Analytics/constants';
import ToggleSwitch from 'components/uiLibrary/ToggleSwitch';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import Button from 'components/uiLibrary/Button';
import Image from 'components/uiLibrary/Image';

import { useTranslation } from 'src/i18n';

import {
  TP,
  BASE_PAGE_ROUTES,
  PROFILE_TYPE_IDS,
  PAYWALL_TYPES,
  SUBSCRIPTION_PRICE,
  PROFILE_TYPES,
} from 'constants/index';
import { REGISTRATION_COHORT_TYPES, MEETING_LINK } from 'containers/Accounts/constants';
import { useActiveProfileData } from 'utils/hooks/useAuthenticatedUser';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useDialogs } from 'utils/hooks/useDialogs';
// eslint-disable-next-line import/no-cycle
import { usePaywallContext } from 'utils/hooks/usePaywallContext';
import { TRACK_EVENTS, PAYWALL_EVENT_IDS } from 'utils/tracking';
import usePageContext from 'utils/hooks/usePageContext';
import { VWO_GOAL_ID, vwoTrackGoal } from 'utils/vwo';

import LogoImage from 'public/images/logo.png';

import classes from './PaywallNudges.module.scss';

const PROFILE_TYPE = {
  AUDIENCE: 'audience',
  ARTIST: 'artist',
  JOURNALIST: 'journalist',
  AOS: 'aos',
  AGENCY: 'agency',
  EDUCATION_ACADEMIA: 'education_academia',
};

const CohortTypeSelector = ({ isLoggedIn, setPaywallType, t, isHardWall, paywallType }) => {
  const { setIsLoginDialog, setIsNewUser } = useDialogs();
  const { navigate } = usePageContext();
  const track = useNewTracking();

  const userTypes = [
    { type: `${TP}.PROAUDIENCE`, id: REGISTRATION_COHORT_TYPES.AUDIENCE, profile_type: PROFILE_TYPE.AUDIENCE },
    {
      type: `${TP}.LP_ARTISTS_AND_PRO`,
      id: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
      profile_type: PROFILE_TYPE.ARTIST,
    },
    {
      type: `${TP}.LP_FOR_AOS`,
      id: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
      profile_type: PROFILE_TYPE.AOS,
    },
    {
      type: `${TP}.LP_FOR_MANAGERS`,
      id: REGISTRATION_COHORT_TYPES.INDUSTRY_PROFESSIONALS,
      profile_type: PROFILE_TYPE.AGENCY,
    },
    {
      type: `${TP}.FOR_JOURNALISTS`,
      id: REGISTRATION_COHORT_TYPES.JOURNALIST_AND_MEDIA,
      profile_type: PROFILE_TYPE.JOURNALIST,
    },
    {
      type: `${TP}.LP_FOR_EDU`,
      id: REGISTRATION_COHORT_TYPES.EDUCATION_AND_ACADEMIA,
      profile_type: PROFILE_TYPE.EDUCATION_ACADEMIA,
    },
  ];

  const handleRegister = useCallback(
    (userTypeId, profile_type) => {
      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          data: {
            id: PAYWALL_EVENT_IDS.PAYWALL_COHORT_CLICK,
            register_type: profile_type,
            actionType: paywallType,
          },
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );

      track.click({
        section: SECTIONS.PAYWALL,
        component: COMPONENTS.COHART_SELECTION,
        subComponent: SUB_COMPONENTS.LOGIN_CTA,
        meta: {
          actionType: paywallType,
          register_type: profile_type,
        },
      });

      if (isLoggedIn) {
        const linkProps = navigate.getLinkProps({
          baseRoute: BASE_PAGE_ROUTES.REGISTER,
          queryParams: { as: userTypeId },
        });
        navigate.to(linkProps);
        setPaywallType('');
      } else {
        setIsNewUser(true);
        setIsLoginDialog({
          isOpen: true,
          as: userTypeId,
          source: isHardWall ? PAYWALL_TYPES.HARD_WALL : PAYWALL_TYPES.SOFT_WALL,
        });
      }
      vwoTrackGoal(VWO_GOAL_ID().PAYWALL_IGNORE);
    },
    [track, paywallType, isLoggedIn, navigate, setPaywallType, setIsNewUser, setIsLoginDialog, isHardWall],
  );

  return (
    <div className={classes.cohortTypeSelector}>
      <div className={classes.cohortTypeSelector__header}>
        <span className={classes.separatorLine}></span>
        <Typography size={14} weight="medium" className={classes.titleCohort}>
          {t('NUDGE_TITLE_MAIN')}
        </Typography>
        <span className={classes.separatorLine}></span>
      </div>
      <div className={classes.cohortTypeSelector__buttonsWrap}>
        {userTypes.map((userType, index) => (
          <Button
            variant="secondary"
            key={index}
            shape="rectangle"
            onClick={() => handleRegister(userType.id, userType.profile_type)}
          >
            {t(userType.type)}
          </Button>
        ))}
      </div>
    </div>
  );
};

const SubscriptionCastingTool = ({ t, isHardWall }) => {
  const { setShowCastingToolSubscription } = usePaywallContext();
  const activeProfile = useActiveProfileData();
  const { setIsOpenCheckout } = useDialogs();
  const { isMobile } = useDeviceTypeLayouts();
  const track = useNewTracking();

  const hasBasicSubscription = useCallback(
    profileType =>
      activeProfile?.subscriptionStatus === PROFILE_TYPES.BASIC && activeProfile?.profileType?.id === profileType,
    [activeProfile?.profileType?.id, activeProfile?.subscriptionStatus],
  );

  const PROFILE_TYPE_FEATURES = {
    default: [
      { name: `${TP}.PROFILE_FEATURE_ADVANCED_FILTER`, icon: 'done' },
      { name: `${TP}.PROFILE_FEATURE_FULL_PERF_HISTORY`, icon: 'done' },
      { name: `${TP}.PROFILE_FEATURE_CAREER_GRAPHS`, icon: 'done' },
      { name: `${TP}.PROFILE_FEATURE_SCHEDULE_FUTURE_REPETORIE`, icon: 'done' },
    ],
    [PROFILE_TYPE_IDS.AOS]: [
      { name: `${TP}.FN_AOS_PRO_ANNUAL_LI_1`, icon: 'done' },
      { name: `${TP}.FN_AOS_PRO_ANNUAL_LI_2`, icon: 'done' },
      { name: `${TP}.FN_AOS_PRO_ANNUAL_LI_3`, icon: 'done' },
    ],
    [PROFILE_TYPE_IDS.AGENT]: [
      { name: `${TP}.FN_AGENCY_CASTING_SUB_LI_1`, icon: 'done' },
      { name: `${TP}.FN_AGENCY_CASTING_SUB_LI_2`, icon: 'done' },
      { name: `${TP}.FN_AGENCY_CASTING_SUB_LI_3`, icon: 'done' },
    ],
  };

  const features = useMemo(() => {
    if (hasBasicSubscription(PROFILE_TYPE_IDS.AOS)) {
      return PROFILE_TYPE_FEATURES[PROFILE_TYPE_IDS.AOS];
    }
    if (hasBasicSubscription(PROFILE_TYPE_IDS.AGENT)) {
      return PROFILE_TYPE_FEATURES[PROFILE_TYPE_IDS.AGENT];
    }
    return PROFILE_TYPE_FEATURES.default;
  }, [hasBasicSubscription, PROFILE_TYPE_IDS.AOS, PROFILE_TYPE_IDS.AGENT]);

  const buttonName = useMemo(() => {
    if (hasBasicSubscription(PROFILE_TYPE_IDS.AOS) || hasBasicSubscription(PROFILE_TYPE_IDS.AGENT)) {
      return `${TP}.BUTTON_GET_SUBSCRIPTION`;
    }
    return `${TP}.GET_CASTING_TOOL`;
  }, [hasBasicSubscription, PROFILE_TYPE_IDS.AOS, PROFILE_TYPE_IDS.AGENT, TP]);

  const subscriptionToggleOptions = useMemo(() => {
    if (hasBasicSubscription(PROFILE_TYPE_IDS.AOS)) {
      return [
        {
          value: 'aos',
          label: t(`${TP}.FN_AOS_PLAN_TITLE_ANNUAL_PRO`),
        },
      ];
    }

    if (hasBasicSubscription(PROFILE_TYPE_IDS.AGENT)) {
      return [
        {
          value: 'agency',
          label: t(`${TP}.FN_AGENCY_CASTING_TOOL_SUBSCRIPTION`),
        },
      ];
    }

    return [
      {
        value: 'castingTool',
        label: 'Casting Tool',
      },
    ];
  }, []);

  const subscriptionPrice = useMemo(() => {
    if (hasBasicSubscription(PROFILE_TYPE_IDS.AGENT)) {
      return { annually: SUBSCRIPTION_PRICE.AGENCY.ANNUALLY, monthly: SUBSCRIPTION_PRICE.AGENCY.MONTHLY };
    }
    return { annually: SUBSCRIPTION_PRICE.CASTING_TOOL.ANNUALLY, monthly: SUBSCRIPTION_PRICE.CASTING_TOOL.MONTHLY };
  }, [hasBasicSubscription]);

  return (
    <div className={classes.subscriptionCastingTool}>
      <div className={classes.subscriptionCastingTool__header}>
        <div className={classes.subscriptionCastingTool__header__demoLink}>
          <LinkButton variant="text" href={MEETING_LINK} isLink external>
            {t(`${TP}.BTN_BOOK_DEMO`)}
          </LinkButton>
        </div>
        <div className={classes.subscriptionCastingTool__header__actions}>
          <Image src={LogoImage} alt="Operabase Home" height={18} width={180} />
          <div>
            <ToggleSwitch options={subscriptionToggleOptions} value={subscriptionToggleOptions[0]?.value} />
          </div>
        </div>
      </div>

      <div className={classes.subscriptionCastingTool__pricing}>
        <div className={classes.subscriptionCastingTool__pricing_left}></div>
        <div className={classes.subscriptionCastingTool__pricing_right}>
          <Typography variant="span">
            <Typography size="16" weight="medium">
              {' '}
              {subscriptionPrice.annually}
            </Typography>
            <br />
            <Typography size="11">
              {subscriptionPrice.monthly} / {t(`${TP}.PAYWALL_MONTHLY_BILLED`)}
            </Typography>
          </Typography>
        </div>
      </div>

      <div className={classes.subscriptionCastingTool__content}>
        <div className={classes.subscriptionCastingTool__featureTable}>
          {features?.map((feature, index) => (
            <div key={index} className={classes.subscriptionCastingTool__featureTable__row}>
              <Typography
                size={isMobile ? 12 : 14}
                className={classes.subscriptionCastingTool__featureTable__row_title}
              >
                {t(feature.name)}
              </Typography>
              <div className={classes.subscriptionCastingTool__featureTable__row_data}>
                <SpriteIcon
                  icon={feature.icon}
                  className={classes.subscriptionCastingTool__featureTable__row_data_icon}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Button
        className={classes.subscriptionCastingTool_button}
        shape="rectangle"
        onClick={() => {
          if (hasBasicSubscription(PROFILE_TYPE_IDS.AOS) || hasBasicSubscription(PROFILE_TYPE_IDS.AGENT)) {
            setIsOpenCheckout(true);
          } else {
            setIsOpenCheckout(true);
            setShowCastingToolSubscription(true);
          }
          track.click(
            {
              name: TRACK_EVENTS.CLICK,
              data: {
                id: PAYWALL_EVENT_IDS.PAYWALL_SUBSCRIPTION_CLICK,
                actionType: isHardWall ? PAYWALL_TYPES.HARD_WALL : PAYWALL_TYPES.SOFT_WALL,
              },
            },
            GOOGLE_OLD_TRACKING_SERVICES,
          );
        }}
      >
        {t(buttonName)}
      </Button>
    </div>
  );
};

const PaywallNudges = ({ hasActiveProfile, isLoggedIn, setPaywallType, isHardWall, paywallType }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <div className={classes.PaywallNudges}>
      {isLoggedIn && hasActiveProfile ? (
        <SubscriptionCastingTool t={t} isHardWall={isHardWall} />
      ) : (
        <CohortTypeSelector
          isLoggedIn={isLoggedIn}
          setPaywallType={setPaywallType}
          paywallType={paywallType}
          t={t}
          isHardWall={isHardWall}
        />
      )}
    </div>
  );
};

export default PaywallNudges;
