/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
export const vwoFormTracking = formInstance => {
  if (formInstance) {
    window.VWO = window.VWO || [];
    window.VWO.push(['nls.formAnalysis.markSuccess', formInstance, 1]);
  }
};

export const vwoTrackGoal = goalid => {
  try {
    window._vis_opt_queue = window._vis_opt_queue || [];
    window._vis_opt_queue.push(() => {
      _vis_opt_goal_conversion(goalid);
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const VWO_GOAL_ID = () => {
  const env = process.env.APP_ENV;
  return {
    REG_COHORT_SUBMITTED_AUDIENCE: env === 'production' ? 204 : 204,
    REG_COHORT_SUBMITTED_IP: env === 'production' ? 205 : 204,
    REG_CONTINUE_CLICKED: env === 'production' ? 205 : 205,
    REG_MOVE_ON_EVENT: env === 'production' ? 206 : 206,
    PAYWALL_IGNORE: env === 'production' ? 207 : 207,
    CHECKOUT_PLAN_RADIO_BUTTON: env === 'production' ? 208 : 208,
    ARTIST_PAYMENT_MODAL: env === 'production' ? 211 : 211,
    ARTIST_CHECKOUT_MODAL: env === 'production' ? 212 : 212,
  };
};
